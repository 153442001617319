import React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid, Box, Divider, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { sectionTitleStyle, smallGrayFontStyle, extraSmallGrayFontStyle } from 'src/styles';
import { EducationCenterType, LessonsDurationByLevel } from 'src/components/Employees/types';

const removeSecondsHandler = (time: string | undefined) => {
    if (time) {
        const timeArray = time.split(':');
        timeArray.pop();
        return timeArray.join(':');
    }
    return '-';
};

const ScheduleGrid: React.FC<LessonsDurationByLevel> = ({ lessonDuration, weekdays }) => {
    const createData = (
        name: string,
        firstLesson: string,
        secondLesson: string,
        thirdLesson: string,
        fourthLesson: string,
        fifthLesson: string,
        sixthLesson: string,
        seventhLesson: string,
        eighthLesson: string
    ) => {
        return {
            name,
            firstLesson,
            secondLesson,
            thirdLesson,
            fourthLesson,
            fifthLesson,
            sixthLesson,
            seventhLesson,
            eighthLesson
        };
    };

    const rows = [
        createData(
            'Початок уроку',
            removeSecondsHandler(weekdays.firstLesson?.from),
            removeSecondsHandler(weekdays.secondLesson?.from),
            removeSecondsHandler(weekdays.thirdLesson?.from),
            removeSecondsHandler(weekdays.fourthLesson?.from),
            removeSecondsHandler(weekdays.fifthLesson?.from),
            removeSecondsHandler(weekdays.sixthLesson?.from),
            removeSecondsHandler(weekdays.seventhLesson?.from),
            removeSecondsHandler(weekdays.eighthLesson?.from)
        ),
        createData(
            'Кінець уроку',
            removeSecondsHandler(weekdays.firstLesson?.to),
            removeSecondsHandler(weekdays.secondLesson?.to),
            removeSecondsHandler(weekdays.thirdLesson?.to),
            removeSecondsHandler(weekdays.fourthLesson?.to),
            removeSecondsHandler(weekdays.fifthLesson?.to),
            removeSecondsHandler(weekdays.sixthLesson?.to),
            removeSecondsHandler(weekdays.seventhLesson?.to),
            removeSecondsHandler(weekdays.eighthLesson?.to)
        )
    ];

    const headerFont = {
        fontWeight: '600'
    } as const;

    const rowsFont = {
        fontWeight: '500'
    } as const;

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead sx={{ backgroundColor: '#F9FAFB' }}>
                    <TableRow>
                        <TableCell>
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>
                                Тривалість уроку - {lessonDuration} хв
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>1-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>2-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>3-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>4-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>5-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>6-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>7-ий урок</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>8-ий урок</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.firstLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.secondLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.thirdLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.fourthLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.fifthLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.sixthLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.seventhLesson}
                            </TableCell>
                            <TableCell sx={[extraSmallGrayFontStyle, rowsFont]} align="right">
                                {row.eighthLesson}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const GeneralCenterInfoTab: React.FC = () => {
    const record = useRecordContext<EducationCenterType>();
    if (!record) return null;

    return (
        <Grid p={3} container columnSpacing={4} rowSpacing={1}>
            <Grid item xs={12}>
                <Box sx={{ ...sectionTitleStyle, mb: 3 }} whiteSpace="normal">
                    Контактна інформація
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mb={1}>
                    Контактний номер телефону
                </Box>
                <Box mb={0.3}>{record.phone}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mb={1}>
                    Адреса
                </Box>
                <Box mb={0.3}>{record.address}</Box>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ ...sectionTitleStyle, mb: 3 }} whiteSpace="normal">
                    Робочі години
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mb={1}>
                    Робочі години
                </Box>
                <Box mb={0.3}>
                    {removeSecondsHandler(record?.workStart)} - {removeSecondsHandler(record?.workEnd)}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mb={1}>
                    Перерва на обід
                </Box>
                <Box mb={0.3}>
                    {removeSecondsHandler(record?.breakStart)} - {removeSecondsHandler(record?.breakEnd)}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ ...sectionTitleStyle, mb: 3 }} whiteSpace="normal">
                    Тривалість уроків
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" mb={1}>
                        Дошкільна освіта
                    </Typography>
                    <ScheduleGrid
                        lessonDuration={record.lessonsDuration.beforeSchoolEducation.lessonDuration}
                        weekdays={record.lessonsDuration.beforeSchoolEducation.weekdays}
                    />
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" mb={1}>
                        1-4 класи
                    </Typography>
                    <ScheduleGrid
                        lessonDuration={record.lessonsDuration.firstFourthGrade.lessonDuration}
                        weekdays={record.lessonsDuration.firstFourthGrade.weekdays}
                    />
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" mb={1}>
                        5-11 класи
                    </Typography>
                    <ScheduleGrid
                        lessonDuration={record.lessonsDuration.fifthTenthGrade.lessonDuration}
                        weekdays={record.lessonsDuration.fifthTenthGrade.weekdays}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default GeneralCenterInfoTab;
