import { AllUserRoles, UserRole } from 'src/auth/types';

export const AdminAndMethodologist = [UserRole.ADMIN, UserRole.METHODOLOGIST]; // on the roles description page - these combined into one column

export const AdminGroup = [UserRole.SYSTEM_ADMIN, ...AdminAndMethodologist, UserRole.SECRETARIAT];

export const SpecialTeachersGroup = [
    UserRole.SPEECH_THERAPIST,
    UserRole.INTELLECTUAL_DISABILITY_TEACHER,
    UserRole.DEAF_TEACHER,
    UserRole.VISION_TEACHER,
    UserRole.TEACHER_PPP
];

export const TeachersGroup = [
    UserRole.PRESCHOOL_TEACHER,
    UserRole.ASSISTANT_PRESCHOOL_TEACHER,
    UserRole.JUNIORS_TEACHER,
    UserRole.ASSISTANT_JUNIORS_TEACHER,
    UserRole.TEACHER,
    UserRole.FOREIGN_LANGUAGE_TEACHER,
    UserRole.SPECIAL_EDUCATION_TEACHER
];

export const AllExceptParentsRolesGroup: UserRole[] = AllUserRoles.filter(
    (value: UserRole) => value !== UserRole.PARENT
);
export const SystemAdminAndSecretaryRolesGroup: UserRole[] = [UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT];
