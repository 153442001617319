import { AppBar, Toolbar, useAuthProvider, useNotify, UserMenu as UserMenuReactAdmin } from 'react-admin';
import UserMenu from 'src/components/UserMenu';
import { palette, Theme } from '../../../Theme';
import './index.css';
import { ReactComponent as OsvCentr } from 'src/assets/osv-centr.svg';
import { ReactComponent as ChevronDown } from 'src/assets/chevron-down.svg';
import { ReactComponent as Ring } from 'src/assets/ring.svg';
import { WithRole } from 'src/components/common';
import { SpecialTeachersGroup, TeachersGroup } from 'src/constants/roles';
import { useEffect, useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import axiosApiInstance from 'src/api/axiosConfig';
import { UserRole } from 'src/auth/types';
import { useNotification } from 'src/utils/notification';
import ShsAlert from 'src/components/common/ShsAlert';
import { NotificationResponse } from 'src/components/common/ShsAlert/constants';

const HeaderStyles = {
    boxShadow: 'none',
    zIndex: 9,
    '& .RaAppBar-toolbar': {
        background: '#FFFFFF',
        borderBottom: `1px solid ${palette.primary.inputBorder}`,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    '& .RaAppBar-toolbar > .RaAppBar-menuButton': { display: 'none' },
    '& .RaAppBar-toolbar > .RaAppBar-title > span.RaConfigurable-root': { display: 'none' }
};

type EduCenter = {
    id: number;
    name: string;
};

export const Header = () => {
    const { changeCenter, getEducationCenterId } = useAuthProvider();
    const notify = useNotify();
    const notification = useNotification();

    const [anchorEl, setAnchorEl] = useState<any | null>(null);
    const [notifications, setNotifications] = useState<any[]>([]);

    const handleClick = (event: any) => {
        setNotifications(notification.getNotification());
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [availableEduCenters, setAvailableEduCenters] = useState<EduCenter[]>([]);
    const eduCenter = availableEduCenters.find((x) => x.id === getEducationCenterId());

    useEffect(() => {
        axiosApiInstance.get('/centers/names').then((response) => setAvailableEduCenters(response.data));
    }, []);

    const handleChangeEduCanter = (id: EduCenter['id']) => {
        changeCenter(id)
            .then(() => {
                window.location.reload();
            })
            .catch(() => {
                notify('Невдалося змінити навчальний центр', {
                    type: 'error',
                    autoHideDuration: 2500
                });
            });
    };

    return (
        <AppBar sx={HeaderStyles} container="div" userMenu={<UserMenu />}>
            {eduCenter && (
                <WithRole roles={[...TeachersGroup, ...SpecialTeachersGroup, UserRole.ADMIN, UserRole.METHODOLOGIST]}>
                    <div style={{ width: '50%' }}>
                        <div
                            style={{
                                color: 'black',
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                            <Box>
                                <OsvCentr />
                            </Box>

                            <Typography fontSize={16} fontWeight={600} color={Theme.palette.primary.midnightBlue}>
                                {eduCenter.name}
                            </Typography>
                            {availableEduCenters.length > 1 && (
                                <UserMenuReactAdmin icon={<ChevronDown />}>
                                    {availableEduCenters
                                        .filter((x) => x.id !== eduCenter.id)
                                        .map((center) => (
                                            <Box
                                                key={center.id}
                                                onClick={() => handleChangeEduCanter(center.id)}
                                                sx={{
                                                    padding: '10px 15px',
                                                    maxWidth: '700px'
                                                }}>
                                                <Typography
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    color={Theme.palette.primary.midnightBlue}>
                                                    {center.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                </UserMenuReactAdmin>
                            )}
                        </div>
                    </div>
                </WithRole>
            )}
            <Toolbar>
                <Box onClick={(e) => handleClick(e)}>
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            background: Theme.palette.error.secondary,
                            borderRadius: '12px',
                            textAlign: 'center',
                            position: 'relative',
                            top: '12px',
                            left: '12px'
                        }}>
                        <Typography fontSize={8} fontWeight={700}>
                            {notification.getNotificationLengthFromLocalStorage()}
                        </Typography>
                    </div>
                    <Ring aria-describedby={id} />
                </Box>
            </Toolbar>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{
                    maxHeight: '90%'
                }}>
                <Box
                    style={{
                        background: '#F1F3F4',
                        padding: '10px'
                    }}>
                    <Typography fontSize={16} fontWeight={500} color={Theme.palette.primary.midnightBlue}>
                        Нотифікації
                    </Typography>
                    <Box
                        style={{
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            paddingTop: '5px'
                        }}>
                        {notifications.length > 0 &&
                            notifications
                                .sort((a, b) => a.daysToExpiration - b.daysToExpiration)
                                .map((x: NotificationResponse) => (
                                    <ShsAlert
                                        key={x.employeeId}
                                        name={x.name}
                                        surname={x.surname}
                                        patronymic={x.patronymic}
                                        employeeId={x.employeeId}
                                        daysToExpiration={x.daysToExpiration}
                                        medicalCheckupExpirationDate={x.medicalCheckupExpirationDate}
                                    />
                                ))}
                        {notifications.length === 0 && (
                            <Box
                                width={'450px'}
                                padding={'20px 0'}
                                color={Theme.palette.primary.gray}
                                textAlign={'center'}>
                                Немає нових нотифікацій
                            </Box>
                        )}
                    </Box>
                </Box>
            </Popover>
        </AppBar>
    );
};
