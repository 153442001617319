export enum LessonOrder {
    firstLesson = 1,
    secondLesson,
    thirdLesson,
    fourthLesson,
    fifthLesson,
    sixthLesson,
    seventhLesson,
    eighthLesson
}
