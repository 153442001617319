import React, { FC, useMemo } from 'react';
import { Datagrid, EditButton, RaRecord, TextField, useListContext } from 'react-admin';

import { palette } from '../../../Theme';
import { UserRoleLabel } from 'src/constants';
import { UserRole } from 'src/auth/types';
import EditIcon from 'src/assets/Edit';
import { WithRole } from 'src/components/common';

export const OrderedDatagrid: FC = () => {
    const { data, page, perPage } = useListContext();
    const enrichedData = useMemo(
        () =>
            data?.map((record: RaRecord, idx: number) => {
                const _orderIdx = (page - 1) * perPage + idx + 1;
                const roles = record.roles.map((x: string) => UserRoleLabel[x as UserRole]).join(', ');
                return { ...record, _orderIdx, roles };
            }),
        [data]
    );

    return (
        <Datagrid
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-headerCell': { color: palette.primary.midnightBlue },
                '& .column-name': { width: '60%' },
                '& .column-roles': { width: '40%' }
            }}
            data={enrichedData}>
            <TextField source="_orderIdx" label="№" sortable={false} />
            <TextField source="name" label="Спеціальні послуги" sortable={false} cellClassName="column-name" />
            <TextField source="roles" label="Спеціальні викладачі" sortable={false} cellClassName="column-roles" />
            <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<></>}>
                <EditButton label="" icon={<EditIcon />} />
            </WithRole>
        </Datagrid>
    );
};
