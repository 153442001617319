import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { TextInputProps, useInput } from 'react-admin';

interface ShsCheckboxProps extends TextInputProps {
    label?: string;
}

const ShsCheckbox: React.FC<ShsCheckboxProps> = ({
    label,
    source,
    onChange,
    defaultValue = false,
    sx = {},
    disabled
}) => {
    const [checked, setChecked] = React.useState(false);
    const { field } = useInput({ source, defaultValue });

    React.useEffect(() => {
        setChecked(Boolean(field.value) || false);
    }, []);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const checkbox = e.target as HTMLInputElement;
        setChecked(checkbox.checked);
        field.onChange(checkbox.checked);
        onChange && onChange(checkbox.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field}
                    checked={checked}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleChange}
                    disabled={disabled}
                />
            }
            label={label}
            sx={sx}
        />
    );
};

export default ShsCheckbox;
