/**
 * Determines if a child's education is currently active based on the provided start and end dates.
 * @param {string} startDate - Start date of the education period.
 * @param {string | undefined | null} endDate - Optional end date of the education period.
 *                                       If undefined, the education is considered ongoing.
 * @returns {boolean} - Returns `true` if the current date is within the education period, `false` otherwise.
 */
export const isChildEducationActive = (startDate: string, endDate: string | undefined | null): boolean => {
    const today = new Date();
    const start = new Date(startDate);

    if (endDate) {
        const end = new Date(endDate);
        return start <= today && today <= end;
    } else {
        return start <= today;
    }
};

export const isChildEducationInactive = (endDate: string | undefined | null): boolean => {
    if (!endDate) return false;

    const today = new Date();
    const end = new Date(endDate);

    return today >= end;
};
