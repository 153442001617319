import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'src/assets/notification.svg';
import { WithRole } from 'src/components/common';
import { AdminGroup } from 'src/constants/roles';
import { ADMIN_MESSAGE, ADMIN_TITLE, EMPLOYEE_MESSAGE, EMPLOYEE_TITLE } from './constants';
import { Theme } from 'src/Theme';

type ShsAlertProps = {
    employeeId?: number;
    name?: string;
    surname?: string;
    patronymic?: string;
    medicalCheckupExpirationDate?: string;
    daysToExpiration?: number;
};

const ShsAlert = ({
    name,
    surname,
    patronymic,
    medicalCheckupExpirationDate,
    daysToExpiration,
    employeeId
}: ShsAlertProps) => {
    return name && surname && patronymic && medicalCheckupExpirationDate && daysToExpiration && employeeId ? (
        <Box
            sx={{
                background: Theme.palette.error.main,
                paddingLeft: '3px',
                borderRadius: '8px'
            }}>
            <Alert
                key={employeeId}
                severity={'error'}
                icon={<Logo fontSize="inherit" />}
                style={{ width: '422px', background: '#fff' }}>
                <AlertTitle>
                    <Typography fontSize={14} fontWeight={600} color={Theme.palette.primary.midnightBlue}>
                        <WithRole roles={[...AdminGroup]} orElse={EMPLOYEE_TITLE}>
                            {ADMIN_TITLE(name, surname, patronymic)}
                        </WithRole>
                    </Typography>
                </AlertTitle>
                <Typography fontSize={11} fontWeight={400} color={Theme.palette.primary.grayBluish}>
                    <WithRole
                        roles={[...AdminGroup]}
                        orElse={EMPLOYEE_MESSAGE(daysToExpiration, medicalCheckupExpirationDate)}>
                        {ADMIN_MESSAGE(name, surname, patronymic, daysToExpiration, medicalCheckupExpirationDate)}
                    </WithRole>
                </Typography>
            </Alert>
        </Box>
    ) : (
        <></>
    );
};

export default ShsAlert;
