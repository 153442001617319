import React from 'react';
import { ArrayInput, FormDataConsumer, SimpleFormIterator, minLength } from 'react-admin';
import { Typography, Box, Grid } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { ShsButton, ShsDatePicker, ShsTextInput } from 'src/components/common';
import { disableFirstRemoveStyle, editContainerStyles, formIteratorStyles, titleFontStyle } from 'src/styles';
import { LinkInput } from '../components/LinkInput';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { palette } from 'src/Theme';

const employeeFormIteratorStyles = {
    '.RaSimpleFormIterator-line': {
        gap: '16px',
        alignItems: 'baseline'
    },
    '.RaSimpleFormIterator-action': {
        visibility: 'visible',
        cursor: 'pointer'
    }
};

const EmployeeDocumentsForm = () => {
    return (
        <Grid sx={editContainerStyles} style={{ background: palette.primary.creamyWhite, padding: '16px 32px' }}>
            <Typography sx={titleFontStyle} variant="h6" component="h6" mb={3}>
                Диплом
            </Typography>
            <ArrayInput
                source="employeeDetails.diplomas"
                label=""
                defaultValue={[{ diplomaLink: '', diplomaSupplementLink: '' }]}
                validate={minLength(1, 'Потрібно додати хоча б один диплом')}>
                <SimpleFormIterator
                    fullWidth
                    disableClear
                    disableReordering
                    sx={{ ...disableFirstRemoveStyle, ...formIteratorStyles, ...employeeFormIteratorStyles }}
                    addButton={
                        <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                            <ShsButton
                                styleType="text"
                                sx={{ p: 0 }}
                                label="Додати диплом"
                                icon={<AddCircleOutline />}
                            />
                        </Box>
                    }
                    removeButton={<DeleteIcon fill={palette.error.main} />}>
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <LinkInput source={getSource('diplomaLink')} label="Диплом" required />
                                    <LinkInput
                                        source={getSource('diplomaSupplementLink')}
                                        label="Додаток до диплому"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>

            <Typography sx={titleFontStyle} variant="h6" component="h6" mt={3} mb={3}>
                Підвищення кваліфікації
            </Typography>
            <ArrayInput
                source="employeeDetails.refresherCertificates"
                label=""
                defaultValue={[{ certificateLink: '' }]}>
                <SimpleFormIterator
                    fullWidth
                    disableClear
                    disableReordering
                    sx={{ ...disableFirstRemoveStyle, ...formIteratorStyles, ...employeeFormIteratorStyles }}
                    addButton={
                        <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                            <ShsButton
                                styleType="text"
                                sx={{ p: 0 }}
                                label="Додати сертифікат"
                                icon={<AddCircleOutline />}
                            />
                        </Box>
                    }
                    removeButton={<DeleteIcon fill={palette.error.main} />}>
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <LinkInput
                                        source={getSource('certificateLink')}
                                        label="Сертифікати підвищення кваліфікації"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>

            <Typography sx={titleFontStyle} variant="h6" component="h6" mt={3} mb={3}>
                Додаткові документи
            </Typography>

            <LinkInput source="employeeDetails.employmentHistoryLink" label="Трудова книжка" required />
            <LinkInput source="employeeDetails.medicalHistoryLink" label="Медична книжка" required />

            <ShsDatePicker
                source="employeeDetails.medicalCheckup"
                label="Дата медичного огляду"
                labelPosition="left"
                required
            />

            <LinkInput
                source="employeeDetails.nameChangeDocumentLink"
                label="Документ про зміну прізвища, імені або по-батькові"
            />
            <LinkInput source="employeeDetails.temporarilyDisplacedDocumentLink" label="Довідка ВПО" />
            <LinkInput source="employeeDetails.attestationLetterLink" label="Атестаційний лист" />

            <ArrayInput source="employeeDetails.additionalDocuments" label="">
                <SimpleFormIterator
                    fullWidth
                    disableClear
                    disableReordering
                    sx={{
                        ...formIteratorStyles,
                        ...employeeFormIteratorStyles,
                        // fixme: form uses several grid input components with different styles, once synced, styles below should be removed
                        '& .RaSimpleFormIterator-action': {
                            marginTop: 'calc(8px + 16px + 10px)' // grid item padding + grid margin + gap between input and label
                        },
                        '& .RaSimpleFormIterator-line': {
                            alignItems: 'center'
                        }
                    }}
                    addButton={
                        <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                            <ShsButton
                                styleType="text"
                                sx={{ p: 0 }}
                                label="Додати інший документ"
                                icon={<AddCircleOutline />}
                            />
                        </Box>
                    }
                    removeButton={<DeleteIcon fill={palette.error.main} />}>
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2} alignItems="end">
                                <Grid item xs={6}>
                                    <ShsTextInput
                                        source={getSource('name')}
                                        label="Назва документу"
                                        placeholder="Вкажіть назву документу"
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ShsTextInput
                                        source={getSource('link')}
                                        placeholder="Вкажіть лінк на файл"
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Grid>
    );
};

export default EmployeeDocumentsForm;
