export enum ErrorType {
    MEDICAL_CHECKUP = 'MEDICAL_CHECKUP'
}

export type NotificationResponse = {
    type: ErrorType;
    userId: number;
    employeeId: number;
    name: string;
    surname: string;
    patronymic: string;
    medicalCheckupExpirationDate: string;
    daysToExpiration: number;
};

export const EMPLOYEE_MESSAGE = (
    daysToExpiration?: number,
    medicalCheckupExpirationDate?: string
) => `Термін дії Вашого останнього медичного огляду закінчується за ${daysToExpiration} днів, а саме ${medicalCheckupExpirationDate}.
Для подальшого доступу до системи необхідно пройти новий медичний огляд. Будь ласка, заплануйте ваш огляд якнайшвидше.
В іншому разі ваш вхід в систему буде заблоковано починаючи від ${medicalCheckupExpirationDate}`;

export const EMPLOYEE_TITLE = `Пройдіть медичний огляд!`;

export const ADMIN_MESSAGE = (
    name?: string,
    surname?: string,
    patronymic?: string,
    daysToExpiration?: number,
    medicalCheckupExpirationDate?: string
) => `Термін дії останнього медичного огляду працівника ${surname} ${name?.charAt(0)}. ${patronymic?.charAt(0)}. закінчується за ${daysToExpiration} днів, а саме ${medicalCheckupExpirationDate}. 
Для подальшого доступу до системи йому(їй) необхідно пройти новий медичний огляд.
Будь ласка, нагадайте про це працівникові. В іншому разі ваш вхід в систему буде заблоковано починаючи від ${medicalCheckupExpirationDate}`;

export const ADMIN_TITLE = (name?: string, surname?: string, patronymic?: string) =>
    `Термін медичного огляду для працівника ${surname} ${name?.charAt(0)}. ${patronymic?.charAt(0)}. завершується!`;
