import React from 'react';
import { EducationalCentersList } from 'src/components/EducationalCenters/EducationalCentersList';
import { EducationalCenterAdd } from 'src/components/EducationalCenters/EducationalCenterAdd';
import { EducationalCenterEdit } from 'src/components/EducationalCenters/EducationalCenterEdit';
import { EducationalCenterView } from 'src/components/EducationalCenters/EducationalCenterView';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import { AllExceptParentsRolesGroup } from 'src/constants/roles';
import { EducationCentersListRolesGroup } from 'src/constants/centers/centers-roles-helper';
import NoAccess from '../common/Errors/NoAccess';

const SystemAdminRole: UserRole[] = [UserRole.SYSTEM_ADMIN];

export default {
    list: (
        <WithRole roles={EducationCentersListRolesGroup} orElse={<NoAccess />}>
            <EducationalCentersList />
        </WithRole>
    ),
    show: (
        <WithRole roles={AllExceptParentsRolesGroup} orElse={<NoAccess />}>
            <EducationalCenterView />
        </WithRole>
    ),
    create: (
        <WithRole roles={SystemAdminRole} orElse={<NoAccess />}>
            <EducationalCenterAdd />
        </WithRole>
    ),
    edit: (
        <WithRole roles={SystemAdminRole} orElse={<NoAccess />}>
            <EducationalCenterEdit />
        </WithRole>
    )
};
