import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { smallGrayFontStyle } from 'src/styles';
import { ShsDateString } from 'src/components/common';
import { ChildFormFields } from 'src/constants';
import { ChildVisit } from 'src/components/Children/types';
import NewEducation from './NewEducation';
import ServiceEndDate from './ServiceEndDate';

interface InstitutionDataProps {
    department: ChildVisit;
}

/**
 * InstitutionData component displays education center and hospital department data,
 * along with the start and end dates for educational services.
 */
export const InstitutionData: React.FC<InstitutionDataProps> = ({ department }) => {
    const [newEducation, setNewEducation] = React.useState(false);
    const serviceEndDate = department?.[ChildFormFields.EDUCATIONAL_SERVICE_END];
    const hospitalDepartmentData = department?.[ChildFormFields.HOSPITAL_DEPARTMENT_DATA];

    return (
        <>
            {newEducation ? (
                <NewEducation cancel={() => setNewEducation(false)} />
            ) : (
                <>
                    <Grid item xs={12} mt={1.5} mb={1}>
                        {hospitalDepartmentData?.departmentName}
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={smallGrayFontStyle} mb={0.3}>
                            Дата згоди на надання освітніх послуг
                        </Box>
                        <ShsDateString record={department} source={ChildFormFields.EDUCATIONAL_AGREEMENT_DATE} />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={smallGrayFontStyle} mb={0.3}>
                            Дата завершення надання освітніх послуг
                        </Box>
                        {serviceEndDate ? (
                            <ShsDateString record={department} source={ChildFormFields.EDUCATIONAL_SERVICE_END} />
                        ) : (
                            <ServiceEndDate />
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

export default InstitutionData;
