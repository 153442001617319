import { UserRole } from 'src/auth/types';
import { EducationLevelGroup } from 'src/constants';
import { LessonsDuration } from 'src/constants/centers';
import { LessonType } from '../enums/lesson.enum';

export const GradeRangeToLessonDuration = {
    [EducationLevelGroup.PRESCHOOL]: LessonsDuration.MINUTES30,
    [EducationLevelGroup.JUNIOR_SCHOOL]: LessonsDuration.MINUTES45,
    [EducationLevelGroup.HIGH_SCHOOL]: LessonsDuration.MINUTES60
};

export const EducationLevelToRolesMap = {
    [EducationLevelGroup.PRESCHOOL]: [
        UserRole.PRESCHOOL_TEACHER,
        UserRole.ASSISTANT_PRESCHOOL_TEACHER,
        UserRole.PSYCHOLOGIST,
        UserRole.SPEECH_THERAPIST,
        UserRole.INTELLECTUAL_DISABILITY_TEACHER,
        UserRole.VISION_TEACHER,
        UserRole.DEAF_TEACHER,
        UserRole.SPECIAL_EDUCATION_TEACHER,
        UserRole.TEACHER_PPP
    ],
    [EducationLevelGroup.JUNIOR_SCHOOL]: [
        UserRole.JUNIORS_TEACHER,
        UserRole.ASSISTANT_JUNIORS_TEACHER,
        UserRole.PSYCHOLOGIST,
        UserRole.SPEECH_THERAPIST,
        UserRole.INTELLECTUAL_DISABILITY_TEACHER,
        UserRole.VISION_TEACHER,
        UserRole.DEAF_TEACHER,
        UserRole.SPECIAL_EDUCATION_TEACHER,
        UserRole.TEACHER_PPP,
        UserRole.FOREIGN_LANGUAGE_TEACHER
    ],
    [EducationLevelGroup.HIGH_SCHOOL]: [
        UserRole.TEACHER,
        UserRole.PSYCHOLOGIST,
        UserRole.SPEECH_THERAPIST,
        UserRole.INTELLECTUAL_DISABILITY_TEACHER,
        UserRole.VISION_TEACHER,
        UserRole.DEAF_TEACHER,
        UserRole.SPECIAL_EDUCATION_TEACHER
    ]
};

export const UserRoleToAPIPathMap: Record<UserRole, string | null> = {
    [UserRole.SYSTEM_ADMIN]: null,
    [UserRole.ADMIN]: null,
    [UserRole.SECRETARIAT]: null,
    [UserRole.PRESCHOOL_TEACHER]: 'teachers',
    [UserRole.ASSISTANT_PRESCHOOL_TEACHER]: 'teachers',
    [UserRole.JUNIORS_TEACHER]: 'teachers',
    [UserRole.ASSISTANT_JUNIORS_TEACHER]: 'teachers',
    [UserRole.TEACHER]: 'teachers',
    [UserRole.PSYCHOLOGIST]: 'psychologists',
    [UserRole.PARENT]: null,
    [UserRole.SPEECH_THERAPIST]: null,
    [UserRole.INTELLECTUAL_DISABILITY_TEACHER]: null,
    [UserRole.VISION_TEACHER]: null,
    [UserRole.DEAF_TEACHER]: null,
    [UserRole.METHODOLOGIST]: null,
    [UserRole.TEACHER_PPP]: null,
    [UserRole.SPECIAL_EDUCATION_TEACHER]: 'teachers',
    [UserRole.FOREIGN_LANGUAGE_TEACHER]: 'teachers'
};

export const UserRoleToLessonTypeMap: Record<UserRole, LessonType | null> = {
    [UserRole.SYSTEM_ADMIN]: null,
    [UserRole.ADMIN]: null,
    [UserRole.SECRETARIAT]: null,
    [UserRole.PRESCHOOL_TEACHER]: LessonType.LESSON,
    [UserRole.ASSISTANT_PRESCHOOL_TEACHER]: LessonType.LESSON,
    [UserRole.JUNIORS_TEACHER]: LessonType.LESSON,
    [UserRole.ASSISTANT_JUNIORS_TEACHER]: LessonType.LESSON,
    [UserRole.TEACHER]: LessonType.LESSON,
    [UserRole.PSYCHOLOGIST]: LessonType.PSYCHOLOGIST_SESSION,
    [UserRole.PARENT]: null,
    [UserRole.SPEECH_THERAPIST]: null,
    [UserRole.INTELLECTUAL_DISABILITY_TEACHER]: null,
    [UserRole.VISION_TEACHER]: null,
    [UserRole.DEAF_TEACHER]: null,
    [UserRole.METHODOLOGIST]: null,
    [UserRole.TEACHER_PPP]: null,
    [UserRole.SPECIAL_EDUCATION_TEACHER]: LessonType.LESSON,
    [UserRole.FOREIGN_LANGUAGE_TEACHER]: LessonType.LESSON
};
