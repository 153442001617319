import { FC, useEffect, useState } from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Labeled, RadioButtonGroupInput, useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';

import { ScheduleEventCard } from '../../common/components/ScheduleEventCard';
import { ShsCheckboxGroup, ShsSelectInput } from 'src/components/common';
import { EducationLevel, EducationLevelGroup, UserRoleLabel } from 'src/constants';
import { UserRole } from 'src/auth/types';
import { Theme, palette } from 'src/Theme';
import { ScheduleCalendarEvent } from '../ScheduleCalendar/ScheduleCalendar.types';
import axiosApiInstance from 'src/api/axiosConfig';
import { shsFormErrorStyles } from 'src/styles';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { ScheduleEmployee } from '../../common/interfaces/ScheduleEmployee.interface';
import { ScheduleChild } from '../../common/interfaces/ScheduleChild.interface';
import { EducationLevelGradeRange } from 'src/api/REST/subjects';
import { ScheduleSubject } from '../../common/interfaces/ScheduleSubject.interface';
import DateTimeUtils from 'src/utils/datetime';
import { EducationLevelToRolesMap, UserRoleToAPIPathMap } from '../../common/const/ScheduleCalendar.const';

const StyledBox = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'highlighted'
})<{ highlighted?: boolean }>(({ highlighted = false }) => {
    return {
        width: '100%',
        minHeight: '54px',
        backgroundColor: highlighted ? palette.primary.gray : palette.primary.grayTint,
        borderRadius: '8px'
    };
});

const StyledLabeled = styled(Labeled)({
    '.RaLabeled-label': {
        fontSize: '14px',
        fontWeight: 600,
        color: palette.primary.midnightBlue
    }
});

const StyledLessonCount = styled(Typography)({
    fontSize: '14px',
    fontWeight: '400',
    color: Theme.palette.primary.grayBluish
});

const StyledHelperText = styled(Typography)({
    fontSize: '11px',
    textAlign: 'center',
    color: Theme.palette.primary.grayBluish
});

const ChildLabel = () => {
    const { name, surname, lessonCount } = useRecordContext();

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>{`${name} ${surname}`}</Typography>
            <StyledLessonCount>{lessonCount}</StyledLessonCount>
        </Stack>
    );
};

const EmployeeLabel = () => {
    const { name, surname, scheduledServicesCount } = useRecordContext();

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>{`${name} ${surname}`}</Typography>
            <StyledLessonCount>{scheduledServicesCount}</StyledLessonCount>
        </Stack>
    );
};

const fetchSubjects = async (
    targetGradeRange: (typeof EducationLevelGradeRange)[EducationLevelGroup]
): Promise<ScheduleSubject[]> => {
    const params = new URLSearchParams({
        targetGradeRange
    });

    const { data } = await axiosApiInstance.get<Record<EducationLevel, ScheduleSubject[]>>(
        `subjects?${params.toString()}`
    );
    const uniqueSubects: Record<number, ScheduleSubject> = Object.values(data)
        .flat()
        .reduce((subjects: Record<number, ScheduleSubject>, subject: ScheduleSubject) => {
            subjects[subject.id] = subject;
            return subjects;
        }, {});

    return Object.values(uniqueSubects);
};

const fetchEmployees = async (
    educationLevelRange: string,
    role: UserRole,
    subjectId: ScheduleSubject['id'],
    rangeStart: Date
): Promise<ScheduleEmployee[] | undefined> => {
    const roleApiPath = UserRoleToAPIPathMap[role];

    const params = new URLSearchParams({
        targetGradeRange: educationLevelRange,
        subjectId: subjectId.toString(),
        weekDate: DateTimeUtils.getFormattedDate(rangeStart)
    });

    const { data } = await axiosApiInstance.get(`employees/${roleApiPath}?${params.toString()}`);
    return data;
};

const fetchChildren = async (
    educationLevelRange: string,
    subjectId: ScheduleSubject['id'],
    rangeStart: Date
): Promise<ScheduleChild[]> => {
    const params = new URLSearchParams({
        targetGradeRange: educationLevelRange,
        subjectId: subjectId.toString(),
        weekDate: DateTimeUtils.getFormattedDate(rangeStart)
    });

    const { data } = await axiosApiInstance.get(`children?${params.toString()}`);
    return data;
};

export const ScheduleSidebar: FC = () => {
    const form = useForm();
    const { calendarConfig, calendarData, setCalendarData } = useScheduleCalendarContext();
    const [calendarEvents, setCalendarEvents] = useState<ScheduleCalendarEvent[]>([]); // todo: should be only one event at a time?

    const role: UserRole = useWatch({ name: 'role', control: form.control });
    const subjectId: number = useWatch({ name: 'subject', control: form.control, defaultValue: null });
    const employeeId: number = useWatch({ name: 'employee', control: form.control, defaultValue: null });
    const childrenIds: number[] = useWatch({
        name: 'children',
        control: form.control,
        defaultValue: []
    });

    const { status: subjectsStatus, data: subjects } = useQuery([calendarConfig.educationLevelGroup], () =>
        fetchSubjects(EducationLevelGradeRange[calendarConfig.educationLevelGroup])
    );

    const { status: employeesStatus, data: employees } = useQuery(
        [
            subjectId,
            role,
            calendarConfig.educationLevelGroup,
            calendarConfig.range.start,
            calendarData.newLesson,
            calendarData.deletedEvent
        ],
        () =>
            fetchEmployees(
                EducationLevelGradeRange[calendarConfig.educationLevelGroup],
                role,
                subjectId,
                calendarConfig.range.start
            ),
        {
            enabled: !!role && !!subjectId
        }
    );

    // todo: newer version of react-query has placeholder data, consider update
    const { status: childrenStatus, data: children } = useQuery<ScheduleChild[]>(
        [
            subjectId,
            calendarConfig.educationLevelGroup,
            calendarConfig.range.start,
            calendarData.newLesson,
            calendarData.deletedEvent
        ],
        () =>
            fetchChildren(
                EducationLevelGradeRange[calendarConfig.educationLevelGroup],
                subjectId,
                calendarConfig.range.start
            ),
        {
            enabled: !!subjectId && !!employeeId
        }
    );

    const resetSidebar = (options: { role?: boolean; subject?: boolean; employee?: boolean; children?: boolean }) => {
        const { role, subject, employee, children } = options;

        setCalendarEvents([]);

        if (role) {
            form.resetField('role', { defaultValue: null });
        }
        if (subject) {
            form.resetField('subject', { defaultValue: null });
        }
        if (employee) {
            form.resetField('employee', { defaultValue: null });
        }
        if (children) {
            form.resetField('children', { defaultValue: [] });
        }
    };

    useEffect(() => {
        resetSidebar({
            role: true,
            subject: true,
            employee: true,
            children: true
        });
    }, [calendarConfig.educationLevelGroup]);

    useEffect(() => {
        resetSidebar({
            subject: true,
            employee: true,
            children: true
        });

        setCalendarData((calendarData) => ({ ...calendarData, role }));
    }, [role]);

    useEffect(() => {
        resetSidebar({
            employee: true,
            children: true
        });
        if (Array.isArray(subjects) && subjectId) {
            const subject = subjects.find((subject) => subject.id === subjectId);
            setCalendarData((calendarData) => ({ ...calendarData, subject }));
        }
    }, [subjectId]);

    useEffect(() => {
        resetSidebar({
            children: true
        });

        let employee: ScheduleEmployee | undefined = undefined;

        if (Array.isArray(employees) && employeeId) {
            employee = employees.find((employee) => employee.id === employeeId);
        }
        setCalendarData((calendarData) => ({
            ...calendarData,
            employee
        }));
    }, [employeeId, employees]);

    useEffect(() => {
        // todo: fix workaround // radio btn group does not emit value on choices arr change
        if (!employees || employees.length === 0) {
            form.setValue('employee', null);
            return;
        }

        form.setValue('employee', employees![0].id);
    }, [employees]);

    useEffect(() => {
        setCalendarData((calendarData) => ({
            ...calendarData,
            children: [],
            employees: []
        }));
        resetSidebar({});

        if (
            Array.isArray(employees) &&
            Array.isArray(children) &&
            Array.isArray(subjects) &&
            employeeId &&
            subjectId &&
            childrenIds.length
        ) {
            const subject = subjects.find((subject) => subject.id === subjectId);
            const employee = employees.find((employee) => +employee.id === +employeeId);

            const selectedChildren: ScheduleChild[] = childrenIds
                .filter(Boolean)
                .map((childId) => children.find((child) => child.id === childId)) as ScheduleChild[];

            setCalendarData((calendarData) => ({
                ...calendarData,
                children: selectedChildren
            }));

            setCalendarEvents((events) => {
                const id = [subject!.id, employeeId, ...selectedChildren.map((child) => child.id)].join('_');
                // todo: replace with one event only if multiple events not possible
                const event: ScheduleCalendarEvent = events.length > 0 ? { ...events[0] } : { id, title: '' };

                event.title = subject!.name;
                event.employee = employee;
                event.children = selectedChildren;

                return [event];
            });
        }
    }, [employees, children, employeeId, childrenIds, subjectId]);

    const isLoading = [subjectsStatus, employeesStatus, childrenStatus].includes('loading');

    return (
        <Stack sx={{ position: 'sticky', top: 80 + 16 }}>
            <div style={{ minHeight: '10px' }}>{isLoading && <LinearProgress />}</div>
            <FormProvider {...form}>
                <ShsSelectInput
                    source="role"
                    label="Для кого складаєте розклад"
                    placeholder="Виберіть роль"
                    choices={EducationLevelToRolesMap[calendarConfig.educationLevelGroup]
                        .filter((userRole) => UserRoleToAPIPathMap[userRole]) // todo: remove once all endpoints are implemented
                        .map((userRole) => ({
                            id: userRole,
                            name: UserRoleLabel[userRole]
                        }))}
                    filter=""
                    autoFocus
                    required
                />

                {role && (
                    <ShsSelectInput
                        source="subject"
                        label="Предмет"
                        choices={subjects?.map((subject) => ({
                            id: subject.id,
                            name: subject.name
                        }))}
                        required
                    />
                )}
                {subjectId && (
                    <StyledLabeled label={UserRoleLabel[role]}>
                        {employees?.length ? (
                            <RadioButtonGroupInput
                                source="employee"
                                parse={Number}
                                choices={employees?.map(({ id, name, surname, scheduledServicesCount }) => ({
                                    id,
                                    name,
                                    surname,
                                    scheduledServicesCount
                                }))}
                                optionText={<EmployeeLabel />}
                                label=""
                                autoFocus={false}
                                sx={{
                                    ...shsFormErrorStyles,
                                    m: 0,
                                    flexDirection: 'column',
                                    '.MuiFormControlLabel-label': {
                                        width: '100%'
                                    }
                                }}
                            />
                        ) : (
                            <StyledHelperText>Працівника не знайдено</StyledHelperText>
                        )}
                    </StyledLabeled>
                )}
                {/* TODO: add grouping by class */}
                {subjectId && employeeId && children?.length ? (
                    <StyledLabeled label="Клас">
                        <ShsCheckboxGroup
                            source="children"
                            choices={
                                children?.map(({ id, name, surname, lessonCount }) => ({
                                    id,
                                    name,
                                    surname,
                                    lessonCount
                                })) || []
                            }
                            optionText={<ChildLabel />}
                            sx={{
                                '.MuiFormControlLabel-label': {
                                    width: '100%'
                                }
                            }}
                        />
                    </StyledLabeled>
                ) : null}
            </FormProvider>

            {subjectId && employeeId && !!childrenIds.length && !!calendarEvents.length && (
                <StyledBox>
                    {calendarEvents.map((event) => (
                        <ScheduleEventCard
                            key={event.id}
                            event={event}
                            dragIcon
                            enabled={!!event.children?.length && !!event.employee}
                        />
                    ))}
                </StyledBox>
            )}

            {!!calendarEvents.length && (
                <StyledHelperText>Щоб додати урок, перетягніть картку на потрібну годину</StyledHelperText>
            )}

            {employeeId && subjectId && !calendarEvents.length && (
                <StyledHelperText>Всі уроки з цього предмету додані в розклад</StyledHelperText>
            )}
        </Stack>
    );
};
