import { useEffect } from 'react';
import { useAuthProvider } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { UserRole } from 'src/auth/types';

export const LandingPage = () => {
    const navigate = useNavigate();
    const authProvider = useAuthProvider();

    useEffect(() => {
        if (authProvider?.containsRoles([UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT])) {
            navigate('/reports');
        } else {
            navigate('/schedule');
        }
    }, [authProvider, navigate]);

    return null;
};
