import { FC, useEffect, useState } from 'react';
import { Card, Tabs, Tab, Box, LinearProgress } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

import { ScheduleCalendarEvent } from './ScheduleCalendar.types';
import { EducationLevelGroup, Resource, EducationLevelGroupList, EducationLevel } from 'src/constants';
import { ScheduleToolbar } from '../ScheduleToolbar/ScheduleToolbar';
import { ScheduleCalendar } from './ScheduleCalendar';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { GradeRangeToLessonDuration } from '../../common/const/ScheduleCalendar.const';
import axiosApiInstance from 'src/api/axiosConfig';
import DateTimeUtils from 'src/utils/datetime';

const fetchLessons = async (educationLevelGroup: EducationLevelGroup, startDate: Date, endDate: Date) => {
    const response = await axiosApiInstance.get(Resource.LESSONS, {
        params: {
            educationLevels: EducationLevelGroupList[educationLevelGroup]
                .reduce<EducationLevel[]>((acc, { id }) => [...acc, id], [])
                .join(','),
            startDate: DateTimeUtils.getFormattedDate(startDate),
            endDate: DateTimeUtils.getFormattedDate(endDate)
        }
    });

    return response.data;
};

const fetchSubjects = async (): Promise<any[]> => {
    // todo: type differs from regular subject
    const body = {
        page: 0,
        size: 999,
        sort: {
            properties: ['id'],
            direction: 'ASC'
        }
    };

    try {
        const { data } = await axiosApiInstance.post('subjects', body);
        return data.content;
    } catch (e) {
        console.error(e);
        return [];
    }
};

const getCalendarEvents = async (
    educationLevelGroup: EducationLevelGroup,
    startDate: Date,
    endDate: Date
): Promise<ScheduleCalendarEvent[]> => {
    const lessons = await fetchLessons(educationLevelGroup, startDate, endDate);
    const subjects = await fetchSubjects();

    const calendarEvents: ScheduleCalendarEvent[] = lessons.map((lesson: any) => {
        const lessonSubject = subjects.find((subject) => subject.id === lesson.subjectId);
        // todo: would be good to get only the needed subjects from BE, not all
        // todo: lesson create takes in user id, but it's not returned in employyee API (only userId)
        // todo: lesson children is null / not null in response to creating the lesson

        return {
            start: DateTimeUtils.convertTimeToDate(lesson.startTime, new Date(lesson.lessonDate)),
            end: DateTimeUtils.convertTimeToDate(lesson.endTime, new Date(lesson.lessonDate)),
            title: lessonSubject!.name,
            educationLevels: lesson.educationLevels,
            subject: lesson.subject,
            id: lesson.id
        };
    });
    return calendarEvents;
};

export const TabbedScheduleCalendar: FC<any> = () => {
    const { setValue } = useFormContext();
    const { calendarConfig, calendarData, setCalendarConfig } = useScheduleCalendarContext();
    const [educationLevelGroup, setEducationLevelGroup] = useState<EducationLevelGroup>(
        calendarConfig.educationLevelGroup
    );

    const { status: calendarEventsStatus, data: calendarEvents = [] } = useQuery(
        [
            calendarConfig.educationLevelGroup,
            calendarConfig.range,
            calendarConfig.viewMode,
            calendarData.newLesson,
            calendarData.deletedEvent
        ],
        () =>
            getCalendarEvents(calendarConfig.educationLevelGroup, calendarConfig.range.start, calendarConfig.range.end)
    );

    const handleChangeTab = (_: any, value: EducationLevelGroup) => {
        setEducationLevelGroup(value);
        setValue(
            'educationLevelGroup',
            EducationLevelGroupList[value].map(({ id }) => id)
        );

        // todo: query grade data from BE && update config if needed
        setCalendarConfig((config) => ({
            ...config,
            lessonDuration: GradeRangeToLessonDuration[value]
        }));
    };

    const viewMode = useWatch({
        name: 'viewMode',
        defaultValue: null
    });

    useEffect(() => {
        setCalendarConfig((config) => ({
            ...config,
            viewMode,
            educationLevelGroup
        }));
    }, [viewMode, educationLevelGroup]);

    return (
        <Box>
            <div style={{ minHeight: '10px' }}>{calendarEventsStatus === 'loading' && <LinearProgress />}</div>
            <ScheduleToolbar />
            <Card sx={{ boxShadow: 'none' }}>
                <Tabs value={educationLevelGroup} onChange={handleChangeTab}>
                    <Tab value={EducationLevelGroup.PRESCHOOL} label="Дошкільна освіта" />
                    <Tab value={EducationLevelGroup.JUNIOR_SCHOOL} label="1-4 класи" />
                    <Tab value={EducationLevelGroup.HIGH_SCHOOL} label="5-11 класи" />
                </Tabs>
                <ScheduleCalendar events={calendarEvents} activeTab={educationLevelGroup} />
            </Card>
        </Box>
    );
};
