import { ComponentProps, FC, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { DragItem } from 'src/constants/drag-and-drop';
import { useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import DateTimeUtils from 'src/utils/datetime';
import { palette } from 'src/Theme';
import { hashCode } from 'src/utils';
import { LessonOrder } from 'src/components/Employees/enums';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { ScheduleBooking } from '../../common/interfaces/ScheduleBooking.interface';
import { LessonsDurationByLevel } from 'src/components/Employees/types';
import { ScheduleCalendarEvent } from './ScheduleCalendar.types';

interface TimeSlotProps extends ComponentProps<any> {
    bookingInfo: ScheduleBooking[];
    scheduleSlots: Partial<LessonsDurationByLevel['weekdays']>;
    onDrop: (dropEvent: ScheduleCalendarEvent, lessonOrder: LessonOrder, slotDate: Date) => any;
}

interface StyledTimeSlotProps extends ComponentProps<any> {
    title?: string;
    highlighted?: boolean;
    isTimeGutter?: boolean;
    isBreakTime?: boolean;
    isWorkTime?: boolean;
    isViewMode?: boolean;
}

export const StyledTimeSlot = styled(
    'div',
    {}
)<StyledTimeSlotProps>(({ title, highlighted, isTimeBooked, isTimeGutter, isViewMode, isWorkTime, isLearningTime }) => {
    let background = 'transparent';
    let opacity = 1;

    if (isTimeGutter || isViewMode) {
        background = 'transparent';
    } else if (!isWorkTime || !isLearningTime || isTimeBooked) {
        background = palette.primary.grayTint;
    } else if (isWorkTime && highlighted) {
        background = palette.lessonColors[Math.abs(hashCode(title?.toString() || '')) % palette.lessonColors.length];
        opacity = 0.2;
    }

    return {
        width: '100%',
        height: '100%',
        background,
        opacity
    };
});

export const TimeSlotWithDrop: FC<TimeSlotProps> = ({
    resource,
    children,
    value,
    onDrop,
    scheduleSlots = {},
    bookingInfo = []
}) => {
    const { calendarData } = useScheduleCalendarContext();

    const isTimeGutter = resource === undefined; // null for regular timeslots // todo: more reliable way?
    const viewMode = useWatch({
        name: 'viewMode',
        defaultValue: null
    });

    const timeSlotFrom = new Date(value).getTime();
    const timeSlotTo = new Date(value).getTime() + 5 * 60 * 1000;

    const lessonSlots = Object.entries(scheduleSlots)
        .filter(([_, slot]) => slot.from && slot.to)
        .map(([lessonOrderName, { from, to }]) => {
            const fromDate = DateTimeUtils.convertTimeToDate(from as string, new Date(value));
            const toDate = DateTimeUtils.convertTimeToDate(to as string, new Date(value));
            const lessonOrder = LessonOrder[lessonOrderName as keyof typeof LessonOrder];

            return { from: fromDate, to: toDate, lessonOrder };
        });

    let isLearningTime = false;
    let lessonOrder: LessonOrder;

    const lessonSlot = lessonSlots.find((lessonTimeSlot: any) => {
        return timeSlotFrom >= lessonTimeSlot.from.getTime() && timeSlotTo <= lessonTimeSlot.to.getTime();
    });

    if (lessonSlot) {
        isLearningTime = true;
        lessonOrder = lessonSlot.lessonOrder;
    }

    const [{ highlighted }, dropRef] = useDrop(
        () => ({
            accept: DragItem.SCHEDULE_CARD,
            drop: (dropEvent: ScheduleCalendarEvent) => onDrop(dropEvent, lessonOrder, new Date(value)),
            collect: (monitor) => ({
                highlighted: monitor.isOver()
            })
        }),
        [value]
    );

    const bookedUserSlots = bookingInfo
        .map((timeSlot) => {
            const date = new Date(timeSlot.date);
            const bookedSlots = timeSlot.bookedTime.map((timeInterval) => {
                const from = DateTimeUtils.convertTimeToDate(timeInterval.from, date);
                const to = DateTimeUtils.convertTimeToDate(timeInterval.to, date);
                return { from, to };
            });

            return bookedSlots;
        })
        .flat();

    const isTimeBooked = bookedUserSlots.some((userTimeSlot) => {
        return timeSlotFrom >= userTimeSlot.from.getTime() && timeSlotTo <= userTimeSlot.to.getTime();
    });

    let isWorkTime = true;

    if (calendarData.educationCenter?.workStart && calendarData.educationCenter?.workEnd) {
        const workTimeFrom = DateTimeUtils.convertTimeToDate(calendarData.educationCenter?.workStart, new Date(value));
        const workTimeTo = DateTimeUtils.convertTimeToDate(calendarData.educationCenter?.workEnd, new Date(value));

        isWorkTime = timeSlotFrom >= workTimeFrom.getTime() && timeSlotTo <= workTimeTo.getTime();
    }

    const isDropAllowed = useMemo(
        () => !isTimeGutter && !isTimeBooked && isWorkTime && isLearningTime,
        [isTimeGutter, isTimeBooked, isWorkTime, isLearningTime]
    );

    return (
        <StyledTimeSlot
            ref={isDropAllowed ? dropRef : null}
            isTimeBooked={isTimeBooked}
            isTimeGutter={isTimeGutter}
            isWorkTime={isWorkTime}
            isLearningTime={isLearningTime}
            highlighted={highlighted}
            isViewMode={viewMode}>
            {children}
        </StyledTimeSlot>
    );
};
