import React, { useState } from 'react';
import { Edit, SimpleForm, useCreatePath, TextInput, useEditController, useRedirect, useNotify } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Typography } from '@mui/material';
import { get } from 'lodash';

import { ShsReturnButton } from 'src/components/common';
import { editContainerStyles } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { Theme } from 'src/Theme';

import AddToolbar from './Toolbar';
import { PersonalData, EducationInstitutionData, VisitorsList, EducationChildData } from './FieldGroups';
import { CancelEditModal } from '../modals';
import { ValidationErrorModal } from '../modals/ValidationErrorModal';
import { childrenUtils } from 'src/utils';

/**
 * Component for editing a child's profile
 */
export const ChildEdit: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidationErrorModalOpen, setValidationErrorModalOpen] = useState(false);
    const { record } = useEditController();
    const createPath = useCreatePath();
    const notify = useNotify();
    const redirect = useRedirect();
    const form = useForm();

    /**
     * Opens the cancel edit modal
     */
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    /**
     * Closes the cancel edit modal
     */
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    /**
     * Redirects to the child's profile page after successful edit
     */
    const handleRedirect = () => {
        redirect(createPath({ resource: `children/${record.id}/show`, type: 'show' }));
    };

    /**
     * Callback function that is executed on successful edit
     * Displays a success notification and redirects to the child's profile
     */
    const onSuccess = () => {
        notify('Редагування дитини пройшло успішно', {
            type: 'success',
            autoHideDuration: 2500
        });
        handleRedirect();
    };
    const isChildRecordActive = childrenUtils.isChildEducationActive(
        get(record, 'visit.servicesAgreementDate'),
        get(record, 'visit.servicesEndDate')
    );

    /**
     * Handles form validation error
     * Opens validation error modal
     */
    const handleValidationError = () => {
        setValidationErrorModalOpen(true);
        setIsModalOpen(false);
    };

    /**
     * Closes the validation error modal
     */
    const handleCloseValidationErrorModal = () => {
        setValidationErrorModalOpen(false);
    };

    return (
        <FormProvider {...form}>
            <Container sx={editContainerStyles} maxWidth={false}>
                <ShsReturnButton
                    label="Назад до профіля дитини"
                    sx={{ fontSize: Theme.typography.body2.fontSize, color: Theme.palette.primary.grayBluish }}
                    onClick={handleOpenModal}
                />

                <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} redirect="show">
                    <Typography fontSize={'40px'} mb={4} fontWeight="700" color={Theme.palette.primary.midnightBlue}>
                        {isChildRecordActive ? 'Редагувати дитину' : 'Активувати дитину'}
                    </Typography>
                    <SimpleForm toolbar={<AddToolbar handleCancel={handleRedirect} editMode />}>
                        <TextInput sx={{ display: 'none' }} source={ChildFormFields.ID} />
                        <TextInput sx={{ display: 'none' }} source={ChildFormFields.VERSION} />

                        <TextInput sx={{ display: 'none' }} source={`${ChildFormFields.VISIT}.${ChildFormFields.ID}`} />
                        <TextInput
                            sx={{ display: 'none' }}
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.VERSION}`}
                        />

                        <PersonalData />
                        <EducationInstitutionData />
                        <VisitorsList />
                        <EducationChildData />

                        {isModalOpen && (
                            <CancelEditModal
                                isOpen={isModalOpen}
                                isEdit={true}
                                onCancel={handleRedirect}
                                onClose={handleCloseModal}
                                handleValidationError={handleValidationError}
                            />
                        )}

                        {isValidationErrorModalOpen && (
                            <ValidationErrorModal
                                isOpen={isValidationErrorModalOpen}
                                onClose={handleCloseValidationErrorModal}
                            />
                        )}
                    </SimpleForm>
                </Edit>
            </Container>
        </FormProvider>
    );
};
