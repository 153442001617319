import { AllUserRoles, UserRole } from 'src/auth/types';

// Used in menu items to define list views
export const EducationCentersListRolesGroup: UserRole[] = [UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT];

const EducationCentersNoEligibleRoles: UserRole[] = [UserRole.PARENT, ...EducationCentersListRolesGroup];
// Used in menu items to define show views
export const EducationCenterOnlyShowRolesGroup: UserRole[] = AllUserRoles.filter(
    (value: UserRole) => !EducationCentersNoEligibleRoles.includes(value)
);
