import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Toolbar, useCreatePath, useDelete, useNotify, useRecordContext, useRedirect, useUpdate } from 'react-admin';
import { Box } from '@mui/material';

import { shsButtonStyles } from 'src/styles';

import { ShsButton, WithRole } from 'src/components/common';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { Resource } from 'src/constants';
import { DeleteModalEmployee } from '../modals';
import { useNavigate } from 'react-router';
import { UserRole } from 'src/auth/types';
import ShsFormToolbar from 'src/components/common/ShsFormToolbar';
import { CancelEditEmployeeModal } from '../modals/CancelEditEmployeeModal';
import { ValidationErrorModal } from 'src/components/Children/modals/ValidationErrorModal';

export type ModalProps = {
    isOpen: boolean;
    onConfirm?: () => void;
    onClose: () => void;
};

export interface ShsFormToolbarProps {
    submitLabel?: string;
    cancelLabel?: string;
    cancelDialog?: React.FC<ModalProps>;
    enableDeleteButton?: boolean;
    enableCancelButton?: boolean;
    deleteLabel?: string;
    deleteConfirmContent?: React.ReactNode;
    editMode?: boolean;
    handleCancel?: () => void;
}

const EmployeeEditToolbar: React.FC<ShsFormToolbarProps> = ({
    cancelDialog: CancelDialog,
    editMode = false,
    handleCancel = () => {}
}) => {
    const {
        handleSubmit,
        formState: { isValidating, isSubmitting }
    } = useFormContext();
    const [deleteOne] = useDelete();

    const [update] = useUpdate();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);
    const [isValidationErrorModalOpen, setValidationErrorModalOpen] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const record = useRecordContext();
    const navigate = useNavigate();

    /**
     * Closes the delete confirmation modal
     */
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    /**
     * Opens the delete confirmation modal
     */
    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleSaveBtnClick = async () => {
        await handleSubmit((data) => {
            const payload = {
                ...data
            };
            update(
                Resource.EMPLOYEES,
                {
                    id: data.id,
                    data: payload,
                    meta: { useMock: false, method: `update` }
                },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: () => {
                        redirect(createPath({ resource: Resource.EMPLOYEES, type: 'show', id: record?.id }));
                    },
                    onError: (error: any) => {
                        notify(`Error: ${error?.message}`, { type: 'warning', undoable: true });
                    }
                }
            );
        })();
    };

    /**
     * Handles the click event for the delete button
     */
    const handleDeleteBtnClick = () => {
        deleteOne(
            Resource.EMPLOYEES,
            { id: record.id },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Видалення працівника пройшло успішно', {
                        type: 'success',
                        autoHideDuration: 2500
                    });
                    navigate(createPath({ resource: 'employees', type: 'list' }));
                },
                onError: (error: any) => {
                    notify(`Помилка: ${error?.message}`, {
                        type: 'error'
                    });
                }
            }
        );
        setDeleteModalOpen(false);
    };

    /**
     * Opens the cancel confirmation modal
     */
    const handleCancelConfirm = () => {
        setCancelModalOpen(true);
    };

    /**
     * Closes the cancel confirmation modal
     */
    const handleCloseCancelModal = () => {
        setCancelModalOpen(false);
    };

    /**
     * Handles form validation error
     * Opens validation error modal
     */
    const handleValidationError = () => {
        handleCloseCancelModal();
        setValidationErrorModalOpen(true);
    };

    /**
     * Closes the validation error modal
     */
    const handleCloseValidationErrorModal = () => {
        setValidationErrorModalOpen(false);
    };

    const styles = {
        flexGrow: 1,
        '.MuiButton-root': {
            mr: '10px'
        },
        '.ra-delete-button': {
            float: 'right',
            top: '10px'
        }
    };

    const shsToolbarStyles = {
        '&.MuiToolbar-root': {
            '&.RaToolbar-desktopToolbar': {
                marginTop: 0
            },
            background: 'transparent',
            padding: 0,
            width: '100%'
        }
    };

    return (
        <Toolbar sx={shsToolbarStyles}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexGrow: 1 }}>
                <ShsFormToolbar
                    submitLabel="Зберегти зміни"
                    enableSaveButton={true}
                    handleCancel={handleCancelConfirm}
                    sx={shsButtonStyles}
                    enableCancelButton={false}
                    shouldCheckIfDirty={false}
                    handleSave={handleSaveBtnClick}
                />

                {editMode && (
                    <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
                        <ShsButton
                            label={'Bидалити працівника'}
                            sx={{
                                marginTop: '28px',
                                marginRight: 0,
                                whiteSpace: 'nowrap',
                                padding: '0.75em 4em'
                            }}
                            styleType="outline-danger"
                            icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                            onClick={handleOpenDeleteModal}
                        />
                    </WithRole>
                )}
            </Box>

            <CancelEditEmployeeModal
                isOpen={isCancelModalOpen}
                isEdit={editMode}
                onClose={handleCloseCancelModal}
                handleValidationError={handleValidationError}
                onCancel={handleCancel}
            />

            <DeleteModalEmployee
                isOpen={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                onCancel={handleCancel}
                onConfirm={handleDeleteBtnClick}
            />

            {isValidationErrorModalOpen && (
                <ValidationErrorModal isOpen={isValidationErrorModalOpen} onClose={handleCloseValidationErrorModal} />
            )}
        </Toolbar>
    );
};

export default EmployeeEditToolbar;
