import React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';

import CircleButtonPlus from '../../../assets/CircleButtonPlus';
import ShsTitle from '../../common/ShsTitle';
import { shsButtonStyles } from '../../../styles';
import { WithRole } from 'src/components/common';
import { UserRole } from 'src/auth/types';

export const ToolbarActions = () => {
    return (
        <TopToolbar
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '.ShsTitle-root': { marginBottom: 0 }
            }}>
            <ShsTitle title="Спеціальні послуги" />
            <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<></>}>
                <CreateButton
                    label="Додати послугу"
                    variant="contained"
                    icon={<CircleButtonPlus color="white" />}
                    sx={shsButtonStyles}
                />
            </WithRole>
        </TopToolbar>
    );
};
