import React from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';

import { ChildFormFields, ChildGenderList } from 'src/constants';
import { ShsTextInput, ShsRadioButtonGroup, ShsDatePicker } from 'src/components/common';
import { Theme } from 'src/Theme';

export const PersonalData: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="h5" mb={3} fontWeight="600" color={Theme.palette.primary.midnightBlue}>
                Персональні дані дитини
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={ChildFormFields.BIRTH_CERTIFICATE_ID}
                        label="№ свідоцтва про народження"
                        required
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6}>
                    <ShsTextInput source={ChildFormFields.FIRST_NAME} label="Ім'я" defaultValue="" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput source={ChildFormFields.LAST_NAME} label="Прізвище" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        label-sx={{ paddingTop: '16px' }}
                        source={ChildFormFields.PATRONYMIC}
                        label="По батькові"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsDatePicker
                        labelPosition={'top'}
                        source={ChildFormFields.BIRTH_DATE}
                        label="Дата народження"
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 1 }} />
                    <ShsRadioButtonGroup
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        source={ChildFormFields.GENDER}
                        choices={ChildGenderList}
                        label="Стать"
                    />
                    <Divider sx={{ mt: -1, mb: 3 }} />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.RESIDENCE}`}
                        label="Місце фактичного проживання"
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.REGISTRATION}`}
                        label="Місце реєстрації"
                        required
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PersonalData;
