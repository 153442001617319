import React, { useState } from 'react';
import {
    Edit,
    FormGroupContextProvider,
    TabbedForm,
    useCreatePath,
    useEditController,
    useNotify,
    useRedirect
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { employeeEditContainerStyles } from 'src/styles/components/employee/employee';
import { EmployeeType } from './types';
import EmploymentTypeForm from './form/EmploymentTypeForm';
import EmployeeDocumentsForm from './form/EmployeeDocumentsForm';
import EmployeePersonalDetailsForm from './form/EmployeePersonalDetailsForm';
import tabGroups from './const/tab-groups';
import EmployeeEditToolbar from './form/EmployeeEditToolbar';
import { CancelEditEmployeeModal } from './modals/CancelEditEmployeeModal';
import { ValidationErrorModal } from '../Children/modals/ValidationErrorModal';

/**
 * Employee Edit page with three tabs
 */
const EmployeeEdit: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const notify = useNotify();
    const form = useForm();
    const { record } = useEditController();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidationErrorModalOpen, setValidationErrorModalOpen] = useState(false);

    /**
     * Callback function that is executed on successful edit
     * Displays a success notification and redirects to the employee's profile
     */
    const onSuccess = () => {
        notify('Редагування працівника пройшло успішно', {
            type: 'success',
            autoHideDuration: 2500
        });
    };

    /**
     * Opens the cancel edit modal
     */
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    /**
     * Closes the cancel edit modal
     */
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    /**
     * Redirects to the employee's profile page after successful edit
     */
    const handleRedirect = () => {
        redirect(createPath({ resource: `employees/${record.id}/show`, type: 'show' }));
    };

    /**
     * Handles form validation error
     * Opens validation error modal
     */
    const handleValidationError = () => {
        setValidationErrorModalOpen(true);
        setIsModalOpen(false);
    };

    /**
     * Closes the validation error modal
     */
    const handleCloseValidationErrorModal = () => {
        setValidationErrorModalOpen(false);
    };

    return (
        <FormProvider {...form}>
            <Container sx={employeeEditContainerStyles} maxWidth={false}>
                <ShsReturnButton label="Назад до профілю працівника" onClick={handleOpenModal} />
                <Edit<EmployeeType> actions={false} redirect="show" mutationOptions={{ onSuccess }}>
                    <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                        Редагування працівника
                    </Typography>
                    <TabbedForm
                        toolbar={
                            <EmployeeEditToolbar
                                deleteLabel="Видалити працівника"
                                enableDeleteButton
                                enableCancelButton={false}
                                editMode
                            />
                        }>
                        <TabbedForm.Tab label="Персональна інформація">
                            <FormGroupContextProvider name={tabGroups[0]}>
                                <EmployeePersonalDetailsForm />
                            </FormGroupContextProvider>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Документи">
                            <FormGroupContextProvider name={tabGroups[1]}>
                                <EmployeeDocumentsForm />
                            </FormGroupContextProvider>
                        </TabbedForm.Tab>
                        <TabbedForm.Tab label="Працевлаштування">
                            <FormGroupContextProvider name={tabGroups[2]}>
                                <EmploymentTypeForm />
                            </FormGroupContextProvider>
                        </TabbedForm.Tab>
                    </TabbedForm>

                    {isModalOpen && (
                        <CancelEditEmployeeModal
                            isOpen={isModalOpen}
                            isEdit={true}
                            onCancel={handleRedirect}
                            onClose={handleCloseModal}
                            handleValidationError={handleValidationError}
                        />
                    )}

                    {isValidationErrorModalOpen && (
                        <ValidationErrorModal
                            isOpen={isValidationErrorModalOpen}
                            onClose={handleCloseValidationErrorModal}
                        />
                    )}
                </Edit>
            </Container>
        </FormProvider>
    );
};

export default EmployeeEdit;
