export enum EducationLevelGroup {
    PRESCHOOL = 'preschool',
    JUNIOR_SCHOOL = 'juniorSchool',
    HIGH_SCHOOL = 'highSchool'
}

export enum EducationLevelGroupOld { // todo: leave only one enum. refactor code and remove obsolete
    PRESCHOOL_EDUCATION = 'PRESCHOOL_EDUCATION',
    GRADES_1_TO_4 = 'GRADES_1_TO_4',
    GRADES_5_TO_11 = 'GRADES_5_TO_11'
}
