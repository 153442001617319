import axiosApiInstance from 'src/api/axiosConfig';

export const useNotification = () => {
    const getNotification = () => {
        const notifications: any[] = [];

        axiosApiInstance.get(`notifications`).then((response) => {
            notifications.push([...response.data]);
        });

        saveNotificationLengthToLocalStorage(String(notifications.length || 0));

        return notifications;
    };

    const saveNotificationLengthToLocalStorage = (notificationLength: string) => {
        localStorage.setItem('notificationLength', notificationLength);
    };

    const getNotificationLengthFromLocalStorage = () => {
        const notificationLength = localStorage.getItem('notificationLength');
        if (notificationLength) {
            return +notificationLength > 99 ? 99 : notificationLength;
        } else {
            return 0;
        }
    };

    return { getNotification, getNotificationLengthFromLocalStorage };
};
