export enum LessonStatus {
    PLANNED = 'PLANNED',
    CANCELED = 'PLANNED',
    COMPLETED = 'COMPLETED'
}

export enum LessonType {
    PSYCHOLOGIST_SESSION = 'PSYCHOLOGIST_SESSION',
    PSYCHOLOGIST_SESSION_WITH_PARENTS = 'PSYCHOLOGIST_SESSION_WITH_PARENTS',
    LESSON = 'LESSON',
    SPEECH_THERAPIST_SESSION = 'SPEECH_THERAPIST_SESSION',
    OLIGOPHRENOPEDAGOGUE_SESSION = 'OLIGOPHRENOPEDAGOGUE_SESSION',
    TIFLOPEDAGOG_SESSION = 'TIFLOPEDAGOG_SESSION',
    SURDOPEDAGOGIST_SESSION = 'SURDOPEDAGOGIST_SESSION'
}
