import React, { useEffect, useState } from 'react';
import { CreateButton, Datagrid, EditButton, FunctionField, List, TopToolbar, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Tab, Tabs, Box } from '@mui/material';
import { listContainerStyles } from 'src/styles';
import { STATUS } from 'src/constants';
import { UserRole } from 'src/auth/types';
import { ReactComponent as EditIcon } from 'src/assets/pencil-alt.svg';
import { ShsSearchInput, ShsTitle, StatusBadge, WithRole } from '../common';
import { EducationCenterType } from '../Employees/types';
import { textInputSx } from '../common/Fields/ShsTextInput';
import { Theme } from '../../Theme';

/**
 * Renders the button to add educational centers
 */
const ToolbarActions = () => {
    return (
        <TopToolbar>
            <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<></>}>
                <CreateButton label="Додати освітній центр" variant="contained" resource="centers" />
            </WithRole>
        </TopToolbar>
    );
};

/**
 * Renders the search input for filtering educational centers
 */
const Filters: React.FC<{ handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void }> = ({
    handleSearchChange
}) => {
    return (
        <Box sx={Theme.components.RaFilterForm.styleOverrides.root}>
            <ShsSearchInput
                sx={{ ...textInputSx }}
                source="keyword"
                onChange={handleSearchChange}
                helperText={false}
                label="Знайти назву освітнього центру"
                alwaysOn
            />
        </Box>
    );
};

/**
 * Renders the Centers Grid
 */
const CentersGrid: React.FC<{ onTotalChanged?: (total: number) => void }> = ({ onTotalChanged }) => {
    const { total } = useListContext();

    useEffect(() => onTotalChanged?.(total), [total]);

    return (
        <Datagrid bulkActionButtons={false} rowClick="show">
            <FunctionField
                sortBy="name"
                label="Повна назва освітнього центру"
                render={({ name }: EducationCenterType) => <Box>{name}</Box>}
            />
            <FunctionField
                sortBy="shortName"
                label="Скорочена назва"
                render={({ shortName }: EducationCenterType) => <Box>{shortName}</Box>}
            />
            <FunctionField sortBy="city" label="Місто" render={({ city }: EducationCenterType) => <Box>{city}</Box>} />
            <FunctionField
                sortBy="address"
                label="Адреса"
                render={({ address }: EducationCenterType) => <Box>{address}</Box>}
            />
            <FunctionField label="Телефон" render={({ phone }: EducationCenterType) => <Box>{phone}</Box>} />
            <FunctionField
                label="Статус"
                render={({ active }: EducationCenterType) => (
                    <StatusBadge status={active ? STATUS.ACTIVE : STATUS.INACTIVE} />
                )}
            />
            <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<></>}>
                <EditButton icon={<EditIcon />} label="" />
            </WithRole>
        </Datagrid>
    );
};

/**
 * Renders the Educational Centers List with tabs for Active and Inactive centers
 * Provides search functionality to filter centers by name
 */
export const EducationalCentersList: React.FC = () => {
    const [activeTab, setActiveTab] = useState<STATUS.ACTIVE | STATUS.INACTIVE>(STATUS.ACTIVE);
    const [total, setTotal] = useState<number | undefined>(0);
    const [keyword, setKeyword] = useState('');
    const form = useForm();

    /**
     * Handles search input change and updates search keyword
     * @param { React.ChangeEvent<HTMLInputElement> } e - change event triggered by search input
     */
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target?.value;
        if (searchValue?.length >= 2 || searchValue?.length === 0 || !searchValue) {
            setKeyword(searchValue);
        }
    };

    /**
     * Handles tab change and resets search keyword when switching between Active and Inactive tabs
     * @param { React.SyntheticEvent } e - event triggered by tab change
     * @param { STATUS.ACTIVE | STATUS.INACTIVE } tab - new active tab value (either active or inactive status)
     */
    const handleTabChange = (e: React.SyntheticEvent, tab: STATUS.ACTIVE | STATUS.INACTIVE) => {
        setActiveTab(tab);
        setKeyword('');
        form.resetField('keyword');
    };

    return (
        <FormProvider {...form}>
            <Container sx={listContainerStyles}>
                <ShsTitle title="Освітні центри" found={total} foundTitle="Загалом" />
                <List<EducationCenterType>
                    resource="centers"
                    exporter={false}
                    empty={false}
                    actions={<ToolbarActions />}
                    filters={<Filters handleSearchChange={handleSearchChange} />}
                    filter={
                        activeTab === STATUS.ACTIVE
                            ? { active: true, keyword: keyword }
                            : { active: false, keyword: keyword }
                    }>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab value={STATUS.ACTIVE} label="Активні" />
                        <Tab value={STATUS.INACTIVE} label="Неактивні" />
                    </Tabs>
                    <CentersGrid onTotalChanged={(total) => setTotal(total)} />
                </List>
            </Container>
        </FormProvider>
    );
};
