import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { Grid, Box, Link, Divider, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { sectionTitleStyle, smallGrayFontStyle } from 'src/styles';
import { childrenUtils } from 'src/utils';
import { ChildFormFields } from 'src/constants';
import { getSpecialRequirementsString, getDisabilityString, getSocialStatusString } from '../helpers';
import InstitutionData from './InstitutionData';
import { ChildVisit } from 'src/components/Children/types';

export const EducationData: React.FC = () => {
    const record = useRecordContext();
    const visit = record?.[ChildFormFields.VISIT];
    const visitHistory = record?.visitHistory || record?.visit;

    const isActive = childrenUtils.isChildEducationActive(
        get(visit, 'servicesAgreementDate'),
        get(visit, 'servicesEndDate')
    );

    /**
     * Manages the currently expanded accordion index.
     * The initial value is set based on whether the child's education is active.
     * If active, the last item in the visit history is expanded by default.
     */
    const [expandedIndex, setExpandedIndex] = React.useState<number | false>(
        isActive ? visitHistory.length - 1 : false
    );

    /**
     * Toggles the expanded state of an accordion.
     * @param {number} index - The index of the accordion to toggle.
     */
    const handleAccordionToggle = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? false : index));
    };

    return (
        <>
            <Grid item xs={12}>
                <Box sx={sectionTitleStyle}>Відомості про освіту дитини в Школі Супергероїв</Box>
            </Grid>
            <Grid item xs={12}>
                {visitHistory.map((departmentName: ChildVisit, index: number) => {
                    const specialRequirements =
                        departmentName?.[ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS]?.parameters;

                    const isDisability = departmentName?.[ChildFormFields.DISABILITY];
                    const disabilityGroup = departmentName?.[ChildFormFields.DISABILITY_GROUP];
                    const disabilityReason = departmentName?.[ChildFormFields.DISABILITY_REASON];
                    const mediaRecordingLink = departmentName?.[ChildFormFields.MEDIA_RECORDING_LINK];

                    return (
                        <Accordion
                            key={index}
                            disableGutters
                            expanded={expandedIndex === index}
                            onChange={() => handleAccordionToggle(index)}
                            sx={{
                                boxShadow: 'none'
                            }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`${departmentName}-content`}
                                id={`${departmentName}-header`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    padding: '0',
                                    margin: '1em 0',
                                    flexDirection: 'row-reverse',
                                    marginTop: index === 0 ? '1em' : 0,
                                    marginBottom: 0,
                                    borderBottom: '1px solid #DFE4EA',
                                    color: isActive && index === visitHistory.length - 1 ? 'inherit' : '#637381',
                                    backgroundColor: expandedIndex === index ? '#F1FAFA' : 'transparent',
                                    '&:hover, &:active': {
                                        backgroundColor: '#F1FAFA'
                                    }
                                }}>
                                <Box sx={{ flex: '1 1 50%', paddingLeft: '0.4em' }}>
                                    <Typography sx={{ fontWeight: '600' }}>
                                        {departmentName?.educationCenter?.name}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0 0 1em 2em' }}>
                                <Grid container xs={12}>
                                    <InstitutionData department={departmentName} />
                                    <Grid item xs={6}>
                                        <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                                            Особливі освітні потреби:
                                        </Box>
                                        <Box>{getSpecialRequirementsString(specialRequirements) || '-'}</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                                            Дитина має інвалідність
                                        </Box>
                                        <Box>
                                            {(disabilityGroup &&
                                                disabilityReason &&
                                                getDisabilityString(isDisability, disabilityGroup, disabilityReason)) ||
                                                '-'}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                                            Соціальний статус
                                        </Box>
                                        <Box>{getSocialStatusString(visit) || '-'}</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                                            Згода на проведення фото-, кіно-, теле- та відеозйомки дитини
                                        </Box>
                                        {mediaRecordingLink ? (
                                            <Box>
                                                Так,{' '}
                                                <Link
                                                    href={mediaRecordingLink}
                                                    underline="always"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    Ознайомитись зі згодою
                                                </Link>
                                            </Box>
                                        ) : (
                                            '-'
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                                            Додаткова інформація яку необхідно знати
                                        </Box>
                                        <Box>{visit?.info || '-'}</Box>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
        </>
    );
};

export default EducationData;
