import { CalendarRange } from 'src/components/Schedule/common/context/CalendarContext.interface';
import { Time } from 'src/components/Schedule/common/interfaces/ScheduleTime.interface';

export const TIME_REGEX = /^([01]?\d|2[0-3]):([0-5]?\d):([0-5]\d)$/;

export default class DateTimeUtils {
    static getTime(date: Date): string {
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    static getFullDate(date: string | Date, time: Time): Date {
        const { hour, minute, second, nano } = time;

        const milliseconds = Math.floor(nano / 1_000_000);
        const constructedDate = new Date(date);

        constructedDate.setHours(hour);
        constructedDate.setMinutes(minute);
        constructedDate.setSeconds(second);
        constructedDate.setMilliseconds(milliseconds);

        return constructedDate;
    }

    static getFormattedDate = (date: Date): string => {
        // YYYY-MM-DD
        return new Intl.DateTimeFormat('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(date);
    };

    static convertTimeToDate(time: string, date?: Date) {
        if (!TIME_REGEX.test(time)) {
            throw new Error("Invalid time format. Expected 'HH:MM:SS'.");
        }

        const [hour, minute, second] = time.split(':').map(Number);

        return DateTimeUtils.getFullDate(date ?? new Date(), { hour, minute, second, nano: 0 });
    }

    /**
     * @deprecated
     * react big calendar does not provide a way to get inital week range.
     * If things change in the future, this method should be removed
     */
    static getWeekRange(today: Date): CalendarRange {
        const day = today.getDay() || 7;

        const monday = new Date(today);
        monday.setDate(today.getDate() - day + 1);

        const sunday = new Date(today);
        sunday.setDate(monday.getDate() + 6);

        return { start: monday, end: sunday };
    }
}
