import { FC, useEffect, useState } from 'react';
import { Calendar, Views } from 'react-big-calendar';
import localizer, { getCulture } from '../../../common/ShsCalendar/config';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Toolbar } from 'src/components/common/ShsCalendar/components';
import { ScheduleEventCard } from '../../common/components/ScheduleEventCard';
import { styled } from '@mui/system';
import { ScheduleCalendarEvent } from './ScheduleCalendar.types';
import { DragAndDropData } from '../../common/interfaces/DragAndDrop.interface';
import { useWatch } from 'react-hook-form';
import { EducationLevel, EducationLevelGroup } from 'src/constants';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { ScheduleBooking } from '../../common/interfaces/ScheduleBooking.interface';
import { TimeSlotWithDrop } from './TimeSlotWithDrop';
import { UserRoleToLessonTypeMap } from '../../common/const/ScheduleCalendar.const';
import { EducationCenterType, LessonsDurationByLevel } from 'src/components/Employees/types';
import { UserRole } from 'src/auth/types';
import { LessonOrder } from 'src/components/Employees/enums';
import { ScheduleChild } from '../../common/interfaces/ScheduleChild.interface';
import { ScheduleEmployee } from '../../common/interfaces/ScheduleEmployee.interface';
import { ScheduleSubject } from '../../common/interfaces/ScheduleSubject.interface';
import { useNotify } from 'react-admin';
import DateTimeUtils from 'src/utils/datetime';
import { LessonStatus } from '../../common/enums/lesson.enum';
import axiosApiInstance from 'src/api/axiosConfig';
import { ScheduleLesson } from '../../common/interfaces/ScheduleLesson.interface';
import { EducationGroupOldToNewMap } from '../../common/const/EducationGroup.const';

interface ScheduleCalendarProps {
    events: ScheduleCalendarEvent[];
    activeTab: EducationLevelGroup;
}

interface LessonData {
    date: Date;
    role: UserRole;
    lessonOrder: LessonOrder;
    children: ScheduleChild[];
    employee: ScheduleEmployee;
    subject: ScheduleSubject;
    educationCenter: EducationCenterType;
    notes?: string;
    meetingLink?: string;
}

async function createLesson(data: LessonData): Promise<ScheduleLesson> {
    const { date, role, lessonOrder, children, employee, subject, educationCenter, notes, meetingLink } = data;
    const educationLevels = [EducationLevel.YEARS5]; // todo: BE does not return education level for children

    const body = {
        children: children.map((child) => ({ childId: child.id, online: !!meetingLink })),
        lessonNumber: lessonOrder,
        employeeId: employee.id,
        subjectId: subject.id,
        educationCenterId: educationCenter.id,
        lessonDate: DateTimeUtils.getFormattedDate(date),
        status: LessonStatus.PLANNED,
        educationLevels,
        notes,
        meetingLink,
        classroomId: educationCenter.classroomLocations[0].id, // todo: should be selectable on UI?
        type: UserRoleToLessonTypeMap[role]
    };

    return axiosApiInstance.post('lessons/add', body);
}

const CalendarCellDimensions = {
    width: 150,
    height: 70
};

const StyledCalendar = styled(Calendar)({
    minHeight: '800px',
    '.rbc-event': {
        border: 'none',
        padding: 0,
        '&.rbc-selected:focus': {
            outline: '5px auto currentColor'
        }
    },
    '.rbc-time-view': {
        '.rbc-event-label': {
            display: 'none'
        },
        '.rbc-events-container': {
            position: 'static'
        },
        '.rbc-timeslot-group': {
            flexBasis: 'auto',
            height: CalendarCellDimensions.height,
            minWidth: CalendarCellDimensions.width
        },
        '.rbc-time-header-gutter': {
            minWidth: CalendarCellDimensions.width
        },
        '.rbc-day-bg': {
            minWidth: CalendarCellDimensions.width - 1 // border
        },
        '.rbc-header': {
            minWidth: CalendarCellDimensions.width - 1 // border
        }
    }
});

export const ScheduleCalendar: FC<ScheduleCalendarProps> = ({ events, activeTab }) => {
    const { calendarData, calendarConfig, setCalendarConfig, setCalendarData } = useScheduleCalendarContext();
    const [calendarEvents, setCalendarEvents] = useState<ScheduleCalendarEvent[]>([]);
    const notify = useNotify();

    const selectedEducationGroupLevels = useWatch({
        name: 'educationLevelGroup',
        defaultValue: []
    });

    const bookingInfo: ScheduleBooking[] = [];
    if (calendarData?.children) {
        calendarData.children.forEach((child) => bookingInfo.push(...child.bookingInfo));
    }
    if (calendarData?.employee) {
        bookingInfo.push(...calendarData.employee.bookingInfo);
    }
    let scheduleSlots: LessonsDurationByLevel['weekdays'];
    if (calendarData?.educationCenter) {
        const oldEducationGroupName = EducationGroupOldToNewMap[calendarConfig.educationLevelGroup];

        // @ts-ignore // todo: correct this once backend is aligned with FE in education level naming
        scheduleSlots = calendarData.educationCenter.lessonsDuration[oldEducationGroupName].weekdays;
    }

    useEffect(() => {
        if (calendarConfig.viewMode) {
            events = events.filter((calendarEvent) =>
                calendarEvent.educationLevels?.some((value) => selectedEducationGroupLevels.includes(value))
            );
        }

        setCalendarEvents(Array.isArray(events) ? events : []);
    }, [events, selectedEducationGroupLevels, calendarConfig]);

    function handleScheduleEventDragEnd({ item }: DragAndDropData) {
        setCalendarEvents((calendarEvents) => calendarEvents.filter((calendarEvent) => calendarEvent.id !== item.id));
    }

    const handleTimeslotDrop = async (dropEvent: ScheduleCalendarEvent, lessonOrder: LessonOrder, slotDate: Date) => {
        const { educationCenter, role } = calendarData;
        // events can be dropped outside of the calendar as well as dragged within
        const children = dropEvent.children || calendarData.children;
        const employee = dropEvent.employee || calendarData.employee;
        const subject = dropEvent.subject || calendarData.subject;

        if (!children || !employee || !subject || !educationCenter || !role) {
            throw new Error('Not enough data to create lesson');
        }

        try {
            const newLesson = await createLesson({
                date: slotDate,
                role,
                lessonOrder,
                children,
                employee,
                subject,
                educationCenter
            });
            setCalendarData((data) => ({
                ...data,
                newLesson
            }));
        } catch (error: any) {
            console.error(error);
            notify(error.message, { type: 'error' });
        }
    };

    const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
        let start: Date;
        let end: Date;

        if (Array.isArray(range)) {
            // week view
            start = range[0];
            end = range[range.length - 1];
        } else {
            // month, agenda view
            start = range.start;
            end = range.end;
        }

        setCalendarConfig((config) => ({ ...config, range: { start, end } }));
    };

    return (
        <StyledCalendar
            localizer={localizer}
            events={calendarEvents}
            selectable={false}
            defaultView={Views.WEEK}
            timeslots={6}
            step={5}
            min={new Date(0, 0, 0, 8, 0, 0)}
            max={new Date(0, 0, 0, 18, 0, 0)}
            formats={{
                timeGutterFormat: 'HH:mm'
            }}
            culture={getCulture('uk')}
            onRangeChange={handleRangeChange}
            components={{
                toolbar: (props) => <Toolbar {...props} activeTab={calendarConfig.viewMode ? activeTab : undefined} />,
                event: ({ event }) => (
                    <ScheduleEventCard
                        event={event as ScheduleCalendarEvent}
                        onDragEnd={handleScheduleEventDragEnd}
                        allowDelete
                    />
                ),
                timeSlotWrapper: (props) => (
                    <TimeSlotWithDrop
                        {...props}
                        onDrop={handleTimeslotDrop}
                        bookingInfo={bookingInfo}
                        scheduleSlots={scheduleSlots}
                    />
                )
            }}
        />
    );
};
