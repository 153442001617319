import { FC, useEffect } from 'react';
import { SimpleForm, useAuthProvider } from 'react-admin';
import { Container, Box, Stack } from '@mui/material';
import { palette } from 'src/Theme';
import { ScheduleSidebar } from './ScheduleSidebar/ScheduleSidebar';
import { TabbedScheduleCalendar } from './ScheduleCalendar/TabbedScheduleCalendar';
import { useScheduleCalendarContext } from '../common/context/CalendarContext';
import axiosApiInstance from 'src/api/axiosConfig';
import { EducationCenterType } from 'src/components/Employees/types';

const fetchEducationCenter = async (id: number): Promise<EducationCenterType> => {
    const { data } = await axiosApiInstance.get(`centers/${id}`);

    return data;
};

export const ScheduleListView: FC = () => {
    const { calendarConfig, setCalendarData } = useScheduleCalendarContext();
    const { getEducationCenterId } = useAuthProvider();

    useEffect(() => {
        if (getEducationCenterId()) {
            fetchEducationCenter(getEducationCenterId()).then((educationCenter) => {
                setCalendarData((data) => ({ ...data, educationCenter }));
            });
        }
    }, []);

    return (
        <Box width={'100vw'}>
            <SimpleForm toolbar={false} sx={{ padding: 0 }}>
                <Stack direction={'row'} sx={{ backgroundColor: 'white' }}>
                    {!calendarConfig.viewMode && (
                        <Container sx={{ padding: '16px 8px', width: '280px' }}>
                            <ScheduleSidebar />
                        </Container>
                    )}
                    <Container
                        sx={{
                            backgroundColor: palette.primary.grayLight,
                            margin: 0,
                            padding: '35px 16px',
                            '@media (min-width: 1200px)': {
                                maxWidth: 'fit-content'
                            }
                        }}>
                        <TabbedScheduleCalendar />
                    </Container>
                </Stack>
            </SimpleForm>
        </Box>
    );
};
