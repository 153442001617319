import { FC, ReactNode, createContext, useContext, useState } from 'react';

import { ScheduleCalendarData, ScheduleCalendarConfig, ScheduleCalendarContextData } from './CalendarContext.interface';
import { EducationLevelGroup } from 'src/constants';
import DateTimeUtils from 'src/utils/datetime';

const ScheduleCalendarContext = createContext<ScheduleCalendarContextData | null>(null);

export const useScheduleCalendarContext = () => {
    const context = useContext(ScheduleCalendarContext);

    if (!context) {
        throw new Error('useScheduleCalendarContext must be used within a ScheduleCalendarProvider');
    }
    return context;
};

export const ScheduleCalendarProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [calendarData, setCalendarData] = useState<ScheduleCalendarData>({});
    const [calendarConfig, setCalendarConfig] = useState<ScheduleCalendarConfig>({
        viewMode: false,
        educationLevelGroup: EducationLevelGroup.PRESCHOOL,
        range: DateTimeUtils.getWeekRange(new Date())
    });

    return (
        <ScheduleCalendarContext.Provider value={{ calendarData, calendarConfig, setCalendarData, setCalendarConfig }}>
            {children}
        </ScheduleCalendarContext.Provider>
    );
};
