export type AuthUserType = {
    access_token: string;
    refresh_token: string;
    profile: UserType;
};

export type UserType = {
    id: number;
    taxNumber: string;
    name: string;
    surname: string;
    patronymic: string;
    email: string;
    phone: string;
    authorities: UserRole[];
    active: boolean;
};

export enum UserRole {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN', //Системні Адміністратори
    ADMIN = 'ADMIN', // Завідувач
    SECRETARIAT = 'SECRETARIAT', // Працівники секретаріату
    PRESCHOOL_TEACHER = 'PRESCHOOL_TEACHER', // Вихователь
    ASSISTANT_PRESCHOOL_TEACHER = 'ASSISTANT_PRESCHOOL_TEACHER', // Асистент вихователя
    JUNIORS_TEACHER = 'JUNIORS_TEACHER', // Вчитель початкових класів
    ASSISTANT_JUNIORS_TEACHER = 'ASSISTANT_JUNIORS_TEACHER', // Асистент вчителя
    TEACHER = 'TEACHER',
    PSYCHOLOGIST = 'PSYCHOLOGIST', // Практичний психолог
    PARENT = 'PARENT', // Батьки, бабусі-дідусі
    SPEECH_THERAPIST = 'SPEECH_THERAPIST', // Логопед
    INTELLECTUAL_DISABILITY_TEACHER = 'INTELLECTUAL_DISABILITY_TEACHER', // Олігофренопедагог
    VISION_TEACHER = 'VISION_TEACHER', // Тифлопедагог
    DEAF_TEACHER = 'DEAF_TEACHER', // Сурдопедагогc
    METHODOLOGIST = 'METHODOLOGIST', // Методист
    TEACHER_PPP = 'TEACHER_PPP', // Фахівець ППП
    SPECIAL_EDUCATION_TEACHER = 'SPECIAL_EDUCATION_TEACHER', // Вчитель спеціальної освіти
    FOREIGN_LANGUAGE_TEACHER = 'FOREIGN_LANGUAGE_TEACHER' // Вчитель іноземної мови
}

export const AllUserRoles = Object.values(UserRole).filter((value) => typeof value === 'string');
