import React from 'react';
import { TopToolbar as Toolbar, useRecordContext, useRedirect, useCreatePath } from 'react-admin';
import { Box, Stack } from '@mui/material';

import { STATUS } from 'src/constants';
import EditIcon from 'src/assets/Edit';
import { ShsButton, StatusBadge, StarLabel, WithRole } from 'src/components/common';
import { sectionTitleStyle, interFontStyle } from 'src/styles';
import { ReactComponent as CenterAvatar } from 'src/assets/avatar-center.svg';
import { UserRole } from 'src/auth/types';

const CenterTopToolbar: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const record = useRecordContext();
    if (!record) return null;
    const handleEditClick = () => {
        redirect(createPath({ resource: 'centers', type: 'edit', id: record.id }));
    };

    return (
        <Toolbar>
            <Stack display="flex" flexDirection="row" width={1}>
                <Box mr={3}>
                    <CenterAvatar />
                </Box>
                <Stack sx={{ flex: '1 1 100%' }}>
                    <Box sx={{ ...interFontStyle, ...sectionTitleStyle, mt: 1.5 }} whiteSpace="normal">
                        {record.name}
                    </Box>
                    <Box whiteSpace="normal">
                        <StatusBadge status={record.active ? STATUS.ACTIVE : STATUS.INACTIVE} />
                        <StarLabel text={record.shortName} sx={{ mr: 3, mt: 1.5, ml: 3 }} />
                        <StarLabel text={record.city} sx={{ mt: 1.5 }} />
                    </Box>
                </Stack>
                <Box>
                    <WithRole roles={[UserRole.SYSTEM_ADMIN]} orElse={<></>}>
                        <ShsButton
                            label="Редагувати"
                            styleType="outline"
                            icon={<EditIcon />}
                            sx={{ height: '40px' }}
                            onClick={handleEditClick}
                        />
                    </WithRole>
                </Box>
            </Stack>
        </Toolbar>
    );
};

export default CenterTopToolbar;
